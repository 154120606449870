<template>
    <div class="container">
        <div class="content">
            <div class="head-text">
                <p>欢迎使用</p>
                <p>访客预约系统</p>
            </div>
            <div class="style-content">
                <div @click="handleClick1" class="upper">
                    <img style="height: 35px;width: 35px;" src="../assets/img/img3.png">
                    <div>
                        <span>访客登记</span>
                        <span>Visitor</span>
                    </div>
                </div>
                <div @click="handleClick2" class="lower">
                    <img style="height: 40px;width: 40px;" src="../assets/img/img4.png">
                    <div>
                        <span>结果查询</span>
                        <span>Query</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-style-text">
            <span class="p-text2">Copyright@2023 All Rights Reserved.</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "visitor",
        data() {
            return {};
        },
        methods: {
            handleClick1() {
                this.$router.push("/registration?parkingId=" + this.$route.query.parkingId + "&projectName=" + this.$route.query.projectName + "&type1=" + this.$route.query.type1 + "&type2=" + this.$route.query.type2)
            },
            handleClick2() {
                this.$router.push('/visitorRecord')
            },
        }

    }
</script>

<style scoped>
    .container {
        width: 100vw;
        height: 100vh;
        background-color: rgb(225 223 224);
        display: flex;
    }

    .content {
        background: url("../assets/img/visitor.jpg");
        /* 当使用该设置时（方法一），当可视高度不变时，可视宽度变化时，背景图片的高度也会自适应变化 */
        background-size: contain;
        background-repeat: no-repeat;
        /* 把背景图片固定住 */
        background-attachment: fixed;
        width: 100%;
    }

    .head-text {
        margin: 30px 0 0 20px;
        font-size: 20px;
        font-weight: bolder;
        text-align: left;
        /*font-family: monospace;*/
        color: #FFFFFF;
    }

    .style-content {
        display: flex;
        flex-direction: column;
        justify-content: end;
        background-color: rgb(225 223 224);
        border-radius: 15px;
        position: absolute;
        top: 19%;
        left: 0;
        right: 0;
        height: 50vh;
    }

    .upper {
        display: flex;
        position: absolute;
        left: 45%;
        top: 35%;
        width: 206px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background: linear-gradient(-30deg, rgb(77 95 83), rgb(57 67 69));
    }

    .upper img {
        margin: 25px;
    }

    .upper div {
        color: #FFFFFF;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 24px;
        font-size: 16px;
        text-align: left;
    }

    .lower {
        position: absolute;
        left: 45%;
        top: 65%;
        width: 206px;
        display: flex;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background: linear-gradient(-30deg, rgb(228 87 68), rgb(232 111 80));
    }

    .lower img {
        margin: 25px;
    }

    .lower div {
        color: #FFFFFF;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 24px;
        font-size: 16px;
        text-align: left;
    }

    .bottom-style-text {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 4%;
    }

    .bottom-style-text .p-text2 {
        font-family: 微软雅黑;
        font-size: 12px;
        color: #666666;
    }
</style>
